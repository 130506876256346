<template>
	<div class="flex items-center -mr-2 md:hidden">
		<!-- Collapsed Mobile menu button -->
		<DisclosureButton
			class="inline-flex items-center justify-center p-2 rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-mx-navy focus-visible:ring-mx-orange focus-visible:ring-opacity-80 hover:bg-gray-100 hover:text-white"
			:class="color"
			@click="handleClick"
		>
			<span class="sr-only">
				Open main menu
			</span>
			<Bars3Icon
				class="block w-6 h-6"
				aria-hidden="true"
			/>
		</DisclosureButton>
	</div>
</template>

<script setup lang="ts">

import { DisclosureButton } from '@headlessui/vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import { OPEN_HEADER_TRAY } from '@/constants/gtm/events.js'
import type { GA4Event } from '@/types/gtm/events'
import { useTrackEvent } from '@/composables/useEventTracking'

defineProps({
	color: {
		type: String,
		default: 'text-gray-400'
	}
})

function handleClick () {
	const trackedEvent: GA4Event = {
		event: OPEN_HEADER_TRAY,
		category: 'Header',
		action: 'click',
		label: 'Open Header Tray'
	}
	useTrackEvent(trackedEvent)
}
</script>
